import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import * as moment from 'moment';
import Img from "gatsby-image";

const PostOverview = ({ post }) => {
    const { title, featuredImage, date, slug, categories } = post;
    const defaultPostImage = useStaticQuery(graphql`
        query defaultPostImage {
            wintechFile: file(relativePath: { eq: "default-post-image.jpg" }) {
                childImageSharp {
                    thumbnail: fixed(width: 347, height: 215) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
            kingFile: file(relativePath: { eq: "default-post-image-king.jpg" }) {
                childImageSharp {
                    thumbnail: fixed(width: 347, height: 215) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    `);
    const category = categories ? categories.nodes[0] : null;

    let defaultPostImageSrc = defaultPostImage.wintechFile;
    let defaultAltText = 'WinTech Racing logo';
    if(process.env.GATSBY_THEME == "King") {
       defaultPostImageSrc = defaultPostImage.kingFile; 
       defaultAltText = 'King Racing logo';
    }

    // Sets the post image to the image supplied else uses a default post image
    const postImage = featuredImage ? featuredImage.node.imageFile.childImageSharp.thumbnail : defaultPostImageSrc.childImageSharp.thumbnail;
    const postAltTag = featuredImage ? featuredImage.node.altText : defaultAltText;
    
    return (
        <Link className="c-latest" to={`/${slug}/`} aria-label={`View full ${title} article`}>
            <article className="c-latest__inner">
                {category &&
                    <Link className="c-latest__tag" to={`/category/${category.slug}/`} dangerouslySetInnerHTML={{ __html: category.name }}></Link>
                }
                <Img className="c-latest__image" fixed={postImage} alt={postAltTag} />
                {date &&
                    <span className="c-latest__date">{moment(date).format('DD MMM YYYY')}</span>
                }
                {title &&
                    <h1 className="c-latest__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                }
            </article>
        </Link>
    );
};

export default PostOverview;
